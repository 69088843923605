<template>
	<div v-if="item">
		<v-container class="container--narrow">
			<v-card>
				<v-card-text>
					<FilterableList
						v-if="item._details && item._details.length"
						:items="item._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
				<template v-if="item.summary">
					<v-divider />
					<v-card-text>
						{{ item.summary }}
					</v-card-text>
				</template>
				<template v-if="item.c_link">
					<v-divider />
					<v-card-actions>
						<v-btn
							color="primary"
							text
							:href="item.c_link"
							target="_blank"
						>
							<v-icon left>
								mdi-open-in-new
							</v-icon>
							{{ item.c_link_text }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'News',
	data: () => ({
		item: null,
	}),
	computed: {
		...mapState([
			'benefits',
		]),
	},
	mounted () {
		// Find current item from the store
		this.item = this.benefits.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'error404' })
		}

		// Set custom headline
		this.$nextTick(() => {
			this.$root.$emit('setHeadline', this.item.title)
		})
	},
}
</script>
