import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#0574a5',
				secondary: '#20a0af',
				accent: '#eda241',
				error: '#d94a3c',
				info: '#20a0af',
				success: '#31b457',
				warning: '#eda241',
			},
			dark: {
				primary: '#0aabf2',
				secondary: '#5fd2df',
				accent: '#ffb049',
				error: '#d94a3c',
				info: '#0574a5',
				success: '#31b457',
				warning: '#eda241',
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
})
