<i18n>
{
	"en": {
		"buttons": {
			"editYourDetails": "Edit your details"
		},
		"detailsOutdated": {
			"text": "Your information was last updated over a year ago. Please review and, if necessary, update your information."
		},
		"memberships": {
			"noItemsText": "You have no memberships",
			"notValidText": "You have no valid membership",
			"status": {
				"invalid": "Not valid",
				"valid": "Valid"
			},
			"title": "Your membership"
		}
	},
	"fi": {
		"buttons": {
			"editYourDetails": "Muokkaa tietojasi"
		},
		"detailsOutdated": {
			"text": "Tietosi ovat viimeksi päivitetty yli vuosi sitten. Tarkista ja päivitä tarvittaessa tietosi."
		},
		"memberships": {
			"noItemsText": "Sinulla ei ole jäsenyyksiä",
			"notValidText": "Sinulla ei ole voimassa olevaa jäsenyyttä",
			"status": {
				"invalid": "Ei voimassa",
				"valid": "Voimassa"
			},
			"title": "Jäsenyytesi"
		}
	},
	"sv": {
		"buttons": {
			"editYourDetails": "Redigera dina uppgifter"
		},
		"detailsOutdated": {
			"text": "Dina uppgifter redigerades senast för över ett år sedan. Kontrollera och uppdatera dina uppgifter vid behov. "
		},
		"memberships": {
			"noItemsText": "Du har inget medlemsskap",
			"notValidText": "Du har inget giltigt medlemsskap",
			"status": {
				"invalid": "Ogiltigt",
				"valid": "Giltigt"
			},
			"title": "Ditt medlemsskap"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					gradient="rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .6) 100%"
					:src="config._hero_image.url"
					dark
				>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user._membership_summary || user._membership_titles.length"
						class="white--text"
					>
						<span
							v-if="user._membership_summary"
							class="d-block font-weight-bold"
						>
							{{ user._membership_summary }}
						</span>
						<ul
							v-if="user._membership_titles.length"
							class="no-list mt-3"
						>
							<li
								v-for="(item, index) in user._membership_titles"
								:key="index"
							>
								{{ item }}
							</li>
						</ul>
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user._membership_summary || user._membership_titles.length"
						class="white--text"
					>
						<span
							v-if="user._membership_summary"
							class="d-block font-weight-bold"
						>
							{{ user._membership_summary }}
						</span>
						<ul
							v-if="user._membership_titles.length"
							class="no-list mt-3"
						>
							<li
								v-for="(item, index) in user._membership_titles"
								:key="index"
							>
								{{ item }}
							</li>
						</ul>
					</v-card-subtitle>
				</template>
				<v-card-text>
					<v-alert
						v-if="user._membership_status.valid === false"
						type="error"
					>
						{{ $i18n.t('memberships.notValidText') }}.

						<template v-if="user._membership_status.invalidReason">
							{{ user._membership_status.invalidReason }}.
						</template>
					</v-alert>
					<v-alert
						v-if="user._details_outdated === true"
						type="warning"
					>
						{{ $i18n.t('detailsOutdated.text') }}

						<div class="mt-6">
							<v-btn
								color="white"
								outlined
								class="elevation-0"
								:to="{ name: 'myAccount' }"
							>
								<v-icon left>
									mdi-pencil
								</v-icon>
								{{ $i18n.t('buttons.editYourDetails') }}
							</v-btn>
						</div>
					</v-alert>
					<FilterableList
						v-if="user._details && user._details.length"
						:items="user._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="(false)"
						:enable-search="(false)"
						:multiline="true"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						color="primary"
						:to="{ name: 'myAccount' }"
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('buttons.editYourDetails') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'config',
			'user',
			'benefits',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
