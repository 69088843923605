<i18n>
{
	"en": {
		"noItemsMessage": "No news to show."
	},
	"fi": {
		"noItemsMessage": "Uutisia ei löytynyt."
	},
	"sv": {
		"noItemsMessage": "Inga nyheter hittades."
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="user._membership_status.valid === false"
			class="container--narrow"
		>
			<v-alert type="error">
				{{ $i18n.t('messages.contentNotAvailable') }}

				<template v-if="user._membership_status.invalidReason">
					{{ user._membership_status.invalidReason }}.
				</template>
			</v-alert>
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view />
			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'newsContainer'">
				<v-container class="container--narrow">
					<FilterableList
						v-if="items.length"
						subtitle-src="_date_formatted"
						:meta-src="(null)"
						:items="items"
						:multiline="false"
						redirect-url-src="link"
					/>
					<v-alert
						v-else
						type="info"
					>
						{{ $i18n.t('noItemsMessage') }}
					</v-alert>
				</v-container>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'NewsContainer',
	computed: {
		...mapState({
			user: state => state.user,
			items: state => state.news,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
}
</script>
