<template>
	<v-form
		v-if="processedSchema && Object.keys(processedSchema).length"
		v-model="valid"
		enctype="multipart/form-data"
		ref="form"
	>
		<v-row v-if="processedSchema.fields && processedSchema.fields.length">
			<!-- Loop fields as columns -->
			<v-col
				v-for="(field, fieldIndex) in processedSchema.fields"
				:key="fieldIndex"
				v-bind="field.colProps"
				v-show="field.visible && !field.hidden"
			>
				<!-- Insert field depending on type -->
				<h3
					v-if="field.type == 'subtitle' && field.props && field.props.label"
					class="text-subtitle-1 font-weight-bold"
				>
					{{ field.props.label }}
				</h3>
				<v-alert
					v-else-if="field.type == 'info' && field.props && field.props.label"
					type="info"
				>
					{{ field.props.label }}
				</v-alert>
				<v-text-field
					v-else-if="field.type == 'text'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					v-bind="{ ...defaultFieldProps, ...field.props }"
					@input="$emit('change')"
				/>
				<v-textarea
					v-else-if="field.type == 'textarea'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					auto-grow
					outlined
					v-bind="{ ...defaultFieldProps, ...field.props }"
					@input="$emit('change')"
				/>
				<v-checkbox
					v-else-if="field.type == 'checkbox'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					v-bind="{ ...defaultFieldProps, ...field.props }"
					@change="$emit('change')"
				/>
				<v-radio-group
					v-else-if="field.type == 'checkboxes'"
					v-model="valueMutable[field.name]"
					:rules="field.parsedRules"
					:label="field.props.label"
					class="mt-0 mb-0"
					:class="field.class"
					hide-details="auto"
				>
					<v-checkbox
						v-for="option in field.props.items"
						:key="option.id"
						:label="option.title"
						v-model="valueMutable[field.name]"
						:value="option.name"
						:disabled="disabled || field.props.disabled"
						class="mt-0"
						dense
						persistent-hint
						hide-details="auto"
						@change="$emit('change')"
					/>
				</v-radio-group>
				<v-radio-group
					v-else-if="field.type == 'radio'"
					v-model="valueMutable[field.name]"
					:rules="field.parsedRules"
					:label="field.props.label"
					class="mt-0 mb-0"
					:class="field.class"
					hide-details="auto"
				>
					<v-radio
						v-for="option in field.props.items"
						:key="option.id"
						:label="option.title"
						:value="option.name"
						@change="$emit('change')"
					/>
				</v-radio-group>
				<v-switch
					v-else-if="field.type == 'switch'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					v-bind="{ ...defaultFieldProps, ...field.props }"
					@change="$emit('change')"
				/>
				<v-select
					v-else-if="field.type == 'select'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					item-value="name"
					item-text="title"
					v-bind="{ ...defaultFieldProps, ...field.props }"
					@change="$emit('change')"
				/>
				<v-autocomplete
					v-else-if="field.type == 'autocomplete'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					item-value="name"
					item-text="title"
					v-bind="{ ...defaultFieldProps, ...field.props }"
					@change="$emit('change')"
				/>
				<v-file-input
					v-else-if="field.type == 'image'"
					v-model="valueMutable[field.name]"
					:disabled="disabled || field.props.disabled"
					:rules="field.parsedRules"
					:class="field.class"
					v-bind="{ ...defaultFieldProps, ...field.props }"
				/>
				<v-dialog
					v-else-if="field.type == 'date'"
					:ref="('dialog_' + field.name)"
					v-model="field.modalOpen"
					:return-value.sync="valueMutable[field.name]"
					persistent
					width="290px"
				>
					<template
						#activator="{ on }"
					>
						<v-text-field
							v-model="field.dateFormatted"
							:disabled="disabled || field.props.disabled"
							:rules="field.parsedRules"
							:class="field.class"
							readonly
							v-bind="{ ...defaultFieldProps, ...field.props }"
							v-on="on"
							@input="$emit('change')"
							@click:clear="valueMutable[field.name] = null"
						/>
					</template>
					<v-date-picker
						v-model="valueMutable[field.name]"
						scrollable
						v-bind="field.pickerProps"
						locale="fi"
					>
						<v-spacer />
						<v-btn
							text
							color="primary"
							@click="field.modalOpen = false"
						>
							Peruuta
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="saveDialog('dialog_' + field.name, valueMutable[field.name])"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-dialog>
				<div
					v-else-if="field.type == 'buttonGroup' && field.items && field.items.length"
					class="mb-4"
				>
					<p
						v-if="field.props.label"
						class="subtitle-1 mb-2"
					>
						{{ field.props.label }}
						<span v-if="field.rules && field.rules.required">*</span>
					</p>
					<v-btn-toggle
						v-model="valueMutable[field.name]"
						v-bind="field.props"
					>
						<v-btn
							v-for="(item, buttonIndex) in field.items"
							:key="buttonIndex"
							:value="item.value || undefined"
							:disabled="disabled || field.props.disabled"
							v-bind="item.props"
							@click="$emit('change')"
						>
							<v-icon
								v-if="item.icon"
								:left="(item.title && item.title.length) ? true : false"
							>
								{{ item.icon }}
							</v-icon>
							{{ item.title }}
						</v-btn>
					</v-btn-toggle>
					<p
						v-if="field.props.hint"
						class="mt-2 mb-0"
					>
						{{ field.props.hint }}
					</p>
				</div>
				<p v-else>
					<strong>Unknown field type: {{ field.type }}</strong>
				</p>
			</v-col>
		</v-row>

		<!-- Default slot for submit buttons etc -->
		<slot />

		<!-- Debug -->
		<v-expansion-panels v-if="debug">
			<v-expansion-panel>
				<v-expansion-panel-header>
					Debug form data
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<p>
						<strong>Form is valid: </strong>{{ valid }}
					</p>
					<p v-if="valueMutable">
						<strong>Value:</strong><br>
						<pre>{{ valueMutable }}</pre>
					</p>
					<p v-if="processedSchema">
						<strong>Schema:</strong><br>
						<pre>{{ processedSchema }}</pre>
					</p>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-form>
</template>

<script>

import validationRules from '@/utils/validationRules'

export default {
	name: 'SchemaToForm',
	props: {
		// Debug mode
		debug: {
			type: Boolean,
			default: () => {
				return false
			},
		},

		// User defined form schema
		schema: {
			type: Object,
			default: () => {
				return {}
			},
		},

		// Object for field values. Intended to use with v-model.
		value: {
			type: Object,
			default: () => {
				return {
					content: {},
				}
			},
		},

		// Enable field rules?
		enableRules: {
			type: Boolean,
			default: () => {
				return true
			},
		},

		// Disable all form fields?
		disabled: {
			type: Boolean,
			default: () => {
				return false
			},
		},
	},
	data: () => ({
		runValueWatcher: true, // Should value watcher run? We use this to prevent endless loop
		valueMutable: {}, // Mutable form data
		processedSchema: {}, // Processed schema, which we use to render the form
		valid: false, // Is form valid?
		defaultFieldProps: { // Default props for most fields
			'dense': true,
			'persistent-hint': true,
			'hide-details': 'auto',
		},
	}),
	watch: {
		// When schema changes, filter mutable value
		schema: {
			deep: true,
			handler (val) {
				this.log('Schema has changed. Updating mutable value...')
				this.setSchema(val)
				this.valueMutable = this.filterValues(this.value, this.processedSchema)
			},
		},

		// When value changes, filter it to contain only the fields of the schema
		value: {
			deep: true,
			handler (val) {
				if (this.runValueWatcher === false) return

				this.log('Value has changed. Updating mutable value...')
				this.valueMutable = this.filterValues(val, this.processedSchema)
			},
		},

		// When mutable value changes, filter values to contain only the fields in the schema,
		// and emit changes
		valueMutable: {
			deep: true,
			handler (val) {
				this.log('Mutable value has changed. Emitting changes...')

				// Temporarily disable value watcher
				this.runValueWatcher = false

				// Loop all fields
				for (let field of this.processedSchema.fields) {
					// Format date fields
					if (field.type == 'date') {
						this.$set(field, 'dateFormatted', this.formatDate(this.valueMutable[field.name]))
					}

					// If field has conditional visibility defined
					if (field.showIf) {
						field.visible = this.parseCondition(field.showIf)
					} else {
						// By default field is visible
						field.visible = true
					}

					// If field has conditional requirement defined
					if (field.requiredIf) {
						if (this.parseCondition(field.requiredIf) === true) {
							// Add required rule, if it's not already included
							if (!field.rules.includes('required')) {
								field.rules.push('required')
							}
						} else {
							// Remove included rule
							field.rules.splice(field.rules.findIndex(item => item == 'required'), 1)
						}
					}

					// Parse field rules and class
					field.parsedRules = this.parseRules(field.rules)
					field.class = this.parseClass(field)
				}

				// Filter values and emit changes
				this.$emit('input', val)

				// After updating is finished, we can safely enable value watcher
				this.$nextTick(() => {
					this.runValueWatcher = true
				})
			},
		},

		// Emit form validity
		valid (val) {
			return (val) ? this.$emit('valid') : this.$emit('invalid')
		},
	},
	mounted () {
		// Set form schema
		this.setSchema(this.schema)

		// Set mutable value
		this.$nextTick(() => {
			this.valueMutable = this.filterValues(this.value, this.processedSchema)
		})
	},
	methods: {
		// Debug logging
		log (text = '') {
			if (!this.debug || !text) return

			console.log('SchemaToForm: ' + text)
		},

		// Debug warning logging
		logWarn (text = '') {
			if (!this.debug || !text) return

			console.warn('SchemaToForm: ' + text)
		},

		// Debug Error logging
		logError (text = '') {
			if (!this.debug || !text) return

			console.error('SchemaToForm: ' + text)
		},

		// Parse field validation rules. Actual rules are loaded from validationRules module.
		parseRules (keys = []) {
			if (!this.enableRules) return []
			if (!keys || !keys.length) return []

			return keys.map(key => {
				return validationRules[key] || undefined
			})
		},

		// Parse field class
		parseClass (field) {
			if (!field) return []

			return [
				field.rules && (field.rules.includes('required') || field.rules.includes('requiredAllowFalse')) ? 'v-input--required' : null,
			]
		},

		// Parse field condition
		parseCondition (condition = '') {
			if (!condition.length) return true

			const segments = condition.split('=')
			const conditionField = segments[0]
			const conditionValue = segments[1]

			if (['true', '1'].includes(conditionValue)) {
				if (this.debug) this.log('Checking that ' + conditionField + ' is true. Value is ' + this.valueMutable[conditionField])

				return this.valueMutable[conditionField] == true
			} else if (['false', '0'].includes(conditionValue)) {
				if (this.debug) this.log('Checking that ' + conditionField + ' is false. Value is ' + this.valueMutable[conditionField])

				return this.valueMutable[conditionField] == false || this.valueMutable[conditionField] == undefined
			} else if (conditionValue === 'null') {
				if (this.debug) this.log('Checking that ' + conditionField + ' is null. Value is ' + this.valueMutable[conditionField])

				return (this.valueMutable[conditionField] && this.valueMutable[conditionField].length)
			} else {
				return false
			}
		},

		// Save dialog
		saveDialog (dialog, val) {
			this.$refs[dialog][0].save(val)
		},

		// Format date
		formatDate (val) {
			if (!val) return null

			return new Date(val).toLocaleDateString('fi-FI')
		},

		// Set schema to the form and validate field structure
		setSchema (val) {
			try {
				// Copy user defined schema
				let newSchema = JSON.parse(JSON.stringify(val))

				// Field operations
				if (newSchema.fields && newSchema.fields.length) {
					for (let [index, field] of newSchema.fields.entries()) {
						// Check that field has name defined
						if (!field.type) throw 'Field with index ' + index + ' has no type defined.'

						// Check that field has name defined
						if (!field.name) throw 'Field with index ' + index + ' has no name defined.'

						// Merge default colprops with user defined colprops
						field.colProps = Object.assign({
							cols: 12,
						}, field.colProps)

						// Make sure that rules array is set
						field.rules = field.rules || []

						// Parse field class
						field.class = this.parseClass(field)

						// By default field is visible
						field.visible = true
					}
				} else {
					newSchema.fields = []
				}

				// Set processed schema
				this.processedSchema = newSchema
			} catch (error) {
				this.processedSchema = {}
				this.logError('SchemaToForm: ' + error)
			} finally {
				this.$nextTick(() => {
					this.$refs.form.resetValidation()
				})
			}
		},

		// Filter value to contain only the fields in the schema
		filterValues (values = {}, schema = {}) {
			// Get allowed keys
			const allowedKeys = schema.fields.reduce((acc, key) => {
				if (key.type != 'subtitle') acc.push(key.name)

				return acc
			}, [])

			// Filter value
			const filteredValue = Object.keys(values).filter(key => {
				return allowedKeys.includes(key)
			}).reduce((acc, key) => {
				acc[key] = values[key]

				return acc
			}, {})

			return filteredValue
		},
	},
}
</script>

<style lang="scss">

	.v-input--checkbox-group--column > .v-label {
		font-size: 14px;
    padding-bottom: 8px;
	}

</style>
