<i18n>
{
	"en": {
		"clubEvents": {
			"title": "Show events of your club"
		},
		"noItemsMessage": "No events to show."
	},
	"fi": {
		"clubEvents": {
			"title": "Oman kerhon tapahtumat"
		},
		"noItemsMessage": "Tapahtumia ei löytynyt."
	},
	"sv": {
		"clubEvents": {
			"title": "Den egna klubbens händelser"
		},
		"noItemsMessage": "Inga händelser hittades."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'eventContainer'">
			<v-container class="container--narrow">
				<v-btn
					class="mt-4"
					color="secondary"
					href="https://maol.fi/kerhot/"
					target="_blank"
				>
					<v-icon left>
						mdi-open-in-new
					</v-icon>
					{{ $i18n.t('clubEvents.title') }}
				</v-btn>
				<FilterableList
					v-if="items.length"
					subtitle-src="_date_formatted"
					:meta-src="(null)"
					:items="items"
					:multiline="false"
					redirect-url-src="link"
				/>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsMessage') }}
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'EventContainer',
	computed: {
		...mapState({
			items: state => state.events,
		}),
	},
	mounted () {
		this.$api.Events.doRequest()
	},
}
</script>
