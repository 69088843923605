<i18n>
{
	"en": {
		"noItemsMessage": "No news to show."
	},
	"fi": {
		"noItemsMessage": "Uutisia ei löytynyt."
	},
	"sv": {
		"noItemsMessage": "Inga nyheter hittades."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'magazineContainer'">
			<v-container class="container--narrow">
				<FilterableList
					v-if="items.length"
					subtitle-src="_date_formatted"
					:meta-src="(null)"
					:items="items"
					:multiline="false"
					redirect-url-src="link"
				/>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsMessage') }}
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MagazineContainer',
	computed: {
		...mapState({
			items: state => state.magazines,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
}
</script>
