<i18n>
{
	"en": {
		"noItemsMessage": "No benefits to show."
	},
	"fi": {
		"noItemsMessage": "Jäsenetuja ei löytynyt."
	},
	"sv": {
		"noItemsMessage": "Inga medlemsförmåner hittades."
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="user._membership_status.valid === false"
			class="container--narrow"
		>
			<v-alert type="error">
				{{ $i18n.t('messages.contentNotAvailable') }}

				<template v-if="user._membership_status.invalidReason">
					{{ user._membership_status.invalidReason }}.
				</template>
			</v-alert>
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view />
			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'benefitContainer'">
				<v-container class="container--narrow">
					<FilterableList
						v-if="items.length"
						:items="items"
						icon-src="_list_icon"
						:multiline="true"
						@itemClick="itemClick"
					/>
					<v-alert
						v-else
						type="info"
					>
						{{ $i18n.t('noItemsMessage') }}
					</v-alert>
				</v-container>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',
	computed: {
		...mapState({
			user: state => state.user,
			items: state => state.benefits,
		}),
	},
	mounted () {
		this.$api.Benefits.doRequest()
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'benefit', params: { pagename: item.name } })
		},
	},
}
</script>
